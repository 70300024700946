"use strict";

const { default: axios } = require("axios");

// Class definition
var TWICGlobal = function() {

};


TWICGlobal.handleNotifications = function(){
    axios.defaults.withCredentials = true;
    let notificationBtn = document.getElementById('twic-notifications-btn');
    if( notificationBtn !== undefined ){
        notificationBtn.addEventListener('click', function(e){
            document.querySelector('#web-notification-circle-icon').classList.add('d-none');
            let notifications = document.querySelectorAll('.twic-notifications');
            notifications.forEach(function( notification ){
                let notificationID = notification.getAttribute('twic-notification-id');
                axios.patch(appConfig.url+'/not/mark-read/'+notificationID);
            });
        });
    }
}
TWICGlobal.markNotificationAsReadOnClick = function(){
    axios.defaults.withCredentials = true;
    let notifications = document.querySelectorAll('.twic-notifications');
 
    notifications.forEach( async function( notification ){
        notification.addEventListener('click', async function(e){
            e.preventDefault();
            let notificationID = notification.getAttribute('twic-notification-id');
            await axios.patch(appConfig.url+'/not/mark-read/'+notificationID);
            window.location = notification.getAttribute('href');
        });        
    }); 
}
TWICGlobal.handleTestCommunication = function(){
    let testCommunicationElems = document.querySelectorAll('.twic-communication-test-btn');
    if( testCommunicationElems.length > 0 ){
        testCommunicationElems.forEach(function(elem){
            elem.addEventListener('click', function(e){
                let comID = elem.getAttribute('twic-communication-entity-id');
                let comType = elem.getAttribute('twic-communication-entity-type');
                let comIDField =  document.getElementById('twic_communication_entity_id_field');
                let comTypeField =  document.getElementById('twic_communication_entity_type_field');
                if( comID && comType && comIDField && comTypeField){
                    comIDField.value = comID;
                    comTypeField.value = comType;
                }
            });
        });
    }

}


// Global initialization
TWICGlobal.init = function() {

    // TWICGlobal.handleNotifications();
    TWICGlobal.markNotificationAsReadOnClick();
    TWICGlobal.handleTestCommunication();
};

// Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = TWICGlobal;
}
