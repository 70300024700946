"use strict";

const {  axios }  = require('axios');
const { default: Echo }  = require('laravel-echo');
window.Pusher   =  require('pusher-js') ;
const { createApp } =  require('vue');

// Class definition
var ChatGlobal = function() {
};



ChatGlobal.loadEchoAndPusher = function(){

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.PUSHER_APP_KEY,
        cluster: process.env.PUSHER_APP_CLUSTER,
        wsHost: 'yard.twicterminal.com',
        wsPort: 6001,
        wssHost: 'yard.twicterminal.com',
        forceTLS: false,
        namespace: 'App.Events.Messages'
    });

}
ChatGlobal.handleChat = function(el){
    const chatApp = createApp({
        data(){
            return {
                tagify: null,
                chatGroupID: 0,
                msg:'',
                msgID:'',
                to:[],
                me:{
                    id: currentUserID,
                    pic: currentUserPic,
                    name: currentUserName,
                },
                messages:[],
                newMessage: null,
          }

        },
        methods: {
            sendTypingEvent() {
                window.Echo.private('chat-channel')
                    .whisper('typing', {
                        id:this.me.id,
                        name:this.me.name,
                        typing:true
                    });

            },
            showMessageInChat() {
                if( this.newMessage !== null ){
                    this.messages.push(this.newMessage);
                    this.newMessage = null;
                }
            },

            async sendMessage() {

                let receivers = document.querySelector('#chat-recipient-search-field').value;

                if(  this.chatGroupID === 0 && ( receivers == '' || this.msg == '' ) ){
                    return;
                }
                if(  this.chatGroupID === 0 ){
                    await this.createChannel( receivers );
                }

                if( this.chatGroupID != 0 ){

                    if( await this.pushMessage() ){
                        this.newMessage = {
                            id:this.me.id,
                            pic:this.me.pic,
                            name:'You',
                            dateTime: 'Now',
                            msg_id:this.msgID,
                            msg:this.msg,
                            status:'unread'
                        };
                        this.showMessageInChat();
                        this.msg = '';
                        this.msgID = '';
                    }

                }


            },
            async pushMessage(  ){
                let messageSentStatus = false;
                let app = this;

                const postData = {
                    _token:appConfig.csrf,
                    msg:  this.msg,
                    chat_group_id: this.chatGroupID
                };

                // Define the headers for the request
                const headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');

                // Create the request object
                const requestOptions = {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(postData)
                };

                // Make the POST request
                try {
                    const response = await fetch(sendChatMessageUrl, requestOptions); // This is a blocking call

                        if (response.ok) {
                            const data = await response.json();
                            // Process the response data here
                            // console.log( data.resStatus );
                            if( data.resCode == 200){
                                console.log( data );
                                this.msgID = data.data.id;
                                messageSentStatus = true;
                            }
                        } else {
                        // Handle errors
                        console.error("Network response was not ok");
                        }
                    } catch (error) {
                        // Handle other errors, such as network issues
                        console.error("An error occurred:", error);
                    }

                return messageSentStatus;
            },
            messageReceived( messageID ){
                // Define the headers for the request
                const headers = new Headers();
                headers.append('Accept', 'application/json');

                // Create the request object
                const requestOptions = {
                    method: 'GET',
                    headers: headers
                };
                fetch(appConfig.url+'/message/chats/group/received/'+messageID, requestOptions); // This is a blocking call
            },
            messageDelivered( messageID ){
                // Define the headers for the request
                for( let i = 0; i < this.messages.length; i++ ){
                    if( this.messages[i].msg_id == messageID ){
                        this.messages[i].status = 'delivered';
                    }
                }
            },

            async createChannel( receivers ){
                let app = this;
                let users = JSON.parse( receivers );
                let userIDs =  [];

                for (const user of users) {
                    userIDs.push( user.value );
                    }
                const postData = {
                    _token:appConfig.csrf,
                    users:  userIDs
                };
                // Define the headers for the request
                const headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');

                // Create the request object
                const requestOptions = {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(postData)
                };
                try {
                    const response = await fetch(chatGroupCreateUrl, requestOptions); // This is a blocking call

                    if (response.ok) {
                      const data = await response.json();
                      // Process the response data here
                      this.chatGroupID =  data.data.id;
                    } else {
                      // Handle errors
                      console.error("Network response was not ok");
                    }
                  } catch (error) {
                    // Handle other errors, such as network issues
                    console.error("An error occurred:", error);
                  }

            },

            scrollToBottom(){
                var scrollElement = document.querySelector("#twic-instant-chat-box");
                scrollElement.scrollTop = scrollElement.scrollHeight;
            },
            initTagify(el) {
                var inputElm = el;
                var parentApp = this;
                function tagTemplate(tagData) {
                    return `
                        <tag title="${(tagData.title || tagData.email)}"
                                contenteditable='false'
                                spellcheck='false'
                                tabIndex="-1"
                                class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ""}"
                                ${this.getAttributes(tagData)}>
                            <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
                            <div class="d-flex align-items-center">
                                <div class='tagify__tag__avatar-wrap ps-0'>
                                    <img onerror="this.style.visibility='hidden'" class="rounded-circle w-25px me-2" src="${hostUrl}media/${tagData.avatar}">
                                </div>
                                <span class='tagify__tag-text'>${tagData.name}</span>
                            </div>
                        </tag>
                    `
                }

                function suggestionItemTemplate(tagData) {
                    return `
                        <div ${this.getAttributes(tagData)}
                            class='tagify__dropdown__item d-flex align-items-center ${tagData.class ? tagData.class : ""}'
                            tabindex="0"
                            role="option">

                            ${tagData.avatar ? `
                                    <div class='tagify__dropdown__item__avatar-wrap me-2'>
                                        <img onerror="this.style.visibility='hidden'"  class="rounded-circle w-50px me-2" src="${hostUrl}media/${tagData.avatar}">
                                    </div>` : ''
                        }

                            <div class="d-flex flex-column">
                                <strong>${tagData.name}</strong>
                                <span>${tagData.email}</span>
                            </div>
                        </div>
                    `
                }

                // initialize Tagify on the above input node reference
                var tagify = new Tagify(inputElm, {
                    tagTextProp: 'name', // very important since a custom template is used with this property as text. allows typing a "value" or a "name" to match input with whitelist
                    enforceWhitelist: true,
                    skipInvalid: true, // do not remporarily add invalid tags
                    dropdown: {
                        closeOnSelect: false,
                        enabled: 0,
                        classname: 'users-list',
                        searchKeys: ['name', 'email']  // very important to set by which keys to search for suggesttions when typing
                    },
                    templates: {
                        tag: tagTemplate,
                        dropdownItem: suggestionItemTemplate
                    },
                    whitelist: []
                })

                tagify.on('dropdown:show dropdown:updated', onDropdownShow)
                tagify.on('dropdown:select', onSelectSuggestion)
                var controller; // for aborting the call

                // listen to any keystrokes which modify tagify's input
                tagify.on('input', onInput)

                function onInput( e ){
                    var value = e.detail.value
                    tagify.whitelist = null // reset the whitelist

                    // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
                    controller && controller.abort()
                    controller = new AbortController()

                    // show loading animation and hide the suggestions dropdown
                    tagify.loading(true).dropdown.hide()

                    fetch(appConfig.url+'/user/users/name/search?term=' + value, {signal:controller.signal})
                        .then(RES => RES.json())
                        .then( function(newWhitelist){
                        tagify.whitelist = newWhitelist // update whitelist Array in-place
                        tagify.loading(false).dropdown.show(value) // render the suggestions dropdown
                        })
                }
                // tagify.on('add', function(e) {
                //     parentApp.to.push = e.detail.data;
                //     parentApp.$emit('input', e.detail.data);
                // });
                var addAllSuggestionsElm;

                function onDropdownShow(e) {
                    var dropdownContentElm = e.detail.tagify.DOM.dropdown.content;

                    if (tagify.suggestedListItems.length > 1) {
                        addAllSuggestionsElm = getAddAllSuggestionsElm();

                        // insert "addAllSuggestionsElm" as the first element in the suggestions list
                        dropdownContentElm.insertBefore(addAllSuggestionsElm, dropdownContentElm.firstChild)
                    }
                }

                function onSelectSuggestion(e) {
                    if (e.detail.elm == addAllSuggestionsElm)
                        tagify.dropdown.selectAll.call(tagify);
                }

                // create a "add all" custom suggestion element every time the dropdown changes
                function getAddAllSuggestionsElm() {
                    // suggestions items should be based on "dropdownItem" template
                    return tagify.parseTemplate('dropdownItem', [{
                        class: "addAll",
                        name: "Add all",
                        email: tagify.settings.whitelist.reduce(function (remainingSuggestions, item) {
                            return tagify.isTagDuplicate(item.value) ? remainingSuggestions : remainingSuggestions + 1
                        }, 0) + " Members"
                    }]
                    )
                }
                this.tagify = tagify;
            },
            getHumanReadableDate(date){
                const olderDate = new Date(date);
                const currentDate = new Date(this.currentServerDate);
                const diff = olderDate - currentDate;
                const formatter = new Intl.RelativeTimeFormat('en', {
                    numeric: 'auto',
                    style: 'long',
                    localeMatcher: 'best fit'
                });
                let humanReadableDate = "";
                const seconds = Math.round(diff / 1000);
                if(seconds >= 31104000 || seconds <= -31104000)
                    humanReadableDate = formatter.format(Math.round(seconds / 31104000), 'year')
                else if(seconds >= 2592000 || seconds <= -2592000)
                    humanReadableDate = formatter.format(Math.round(seconds / 2592000), 'month')
                else if(seconds >= 604800 || seconds <= -604800)
                    humanReadableDate = formatter.format(Math.round(seconds / 604800), 'week')
                else if(seconds >= 86400 || seconds <= -86400)
                    humanReadableDate = formatter.format(Math.round(seconds / 86400), 'day')
                else if(seconds >= 3600 || seconds <= -3600)
                    humanReadableDate = formatter.format(Math.round(seconds / 3600), 'hour')
                else if(seconds >= 60 || seconds <= -60)
                    humanReadableDate = formatter.format(Math.round(seconds / 60), 'minute')
                else if(seconds < 60 || seconds > -60)
                    humanReadableDate = formatter.format(seconds, 'second')
                // console.log(humanReadableDate);
                return humanReadableDate;
            },
            showChatDrawer( chatElem ){
                this.chatGroupID = chatElem.getAttribute('twic-chat-group-id');
                this.fetchMessages(this.chatGroupID);
                let element = document.querySelector('#kt_drawer_chat');
                let drawer = KTDrawer.getInstance(element);
                if ( !drawer.isShown() ) {
                    drawer.show();
                }
                this.listen( this.chatGroupID );
            },
            async fetchMessages( chatGroupID ){


                // Define the headers for the request
                const headers = new Headers();
                headers.append('Accept', 'application/json');

                // Create the request object
                const requestOptions = {
                    method: 'GET',
                    headers: headers
                };

                // Make the POST request
                try {
                    const response = await fetch(appConfig.url+'/message/chatgroups/'+chatGroupID, requestOptions); // This is a blocking call

                        if (response.ok) {
                            const data = await response.json();
                            // Process the response data here
                            this.messages = [];
                            if( data.resCode == 200){
                                for( let i = 0; i < data.data.length; i++){

                                    this.messages.push({
                                        id:data.data[i].user.id,
                                        pic:data.data[i].user.pic,
                                        name:( data.data[i].user.id == currentUserID) ? 'You' : data.data[i].user.name,
                                        dateTime: this.getHumanReadableDate(data.data[i].created_at),
                                        msg_id:data.data[i].id,
                                        msg:data.data[i].msg,
                                        status:data.data[i].status,
                                    });
                                }
                            }
                        } else {
                        // Handle errors
                        console.error("Network response was not ok");
                        }
                    } catch (error) {
                        // Handle other errors, such as network issues
                        console.error("An error occurred:", error);
                    }
            },
            listen(channelID){
                window.Echo.private(`chat.${channelID}`)
                .listen('NewInstantMessageEvent', (e) => {
                    if( this.messages.length === 0 ){
                        this.fetchMessages(channelID);
                    }
                    var element = document.querySelector('#kt_drawer_chat');
                    var drawer = KTDrawer.getInstance(element);
                    if ( !drawer.isShown() ) {
                        drawer.show();
                    }
                    let user = e.user;
                    let message = e.chat;
                    if( user.id != currentUserID){
                        this.newMessage = {
                            id: user.id,
                            pic: user.pic,
                            name: user.name,
                            dateTime: this.getHumanReadableDate(message.created_at),
                            msg_id:message.id,
                            msg:message.msg,
                            status:message.status,
                        };
                        this.showMessageInChat();
                        this.messageReceived( message.id );
                    }

                    this.chatGroupID =   message.chat_group_id ;
                })
                .listen('MessageDeliveredEvent', (e) => {

                    this.messageDelivered( e.chat.id );

                });
            },
            async subscribeToAllChats(){
                // Define the headers for the request
                const headers = new Headers();
                headers.append('Accept', 'application/json');

                // Create the request object
                const requestOptions = {
                    method: 'GET',
                    headers: headers
                };

                // Make the POST request
                try {
                    const response = await fetch(appConfig.url+'/message/chatgroups/user/'+currentUserID, requestOptions); // This is a blocking call

                        if (response.ok) {
                            const data = await response.json();
                            // Process the response data here
                            this.messages = [];
                            if( data.resCode == 200){
                                for( let i = 0; i < data.data.length; i++){
                                    this.listen(data.data[i].id);
                                }
                            }
                        } else {
                        // Handle errors
                        console.error("Network response was not ok");
                        }
                    } catch (error) {
                        // Handle other errors, such as network issues
                        console.error("An error occurred:", error);
                    }
            }
        },
        mounted() {
            let chatRecipientField = document.getElementById('chat-recipient-search-field');
            if( chatRecipientField ){
                this.initTagify(chatRecipientField);
            }
            let chats = document.querySelectorAll('.chats-list-chat');
            var app = this;
            if( chats ){
                chats.forEach(function( chat ){
                    chat.addEventListener('click', function( e ){
                        //Here the magic should happen
                        app.showChatDrawer( e.target );
                    });
                });
            }
        },
        created() {
             var app = this;
            window.Echo.private('chat-channel')
            .listen('NewChatGroupEvent', (e) => {
                if(e.userIDs.indexOf(currentUserID) !== -1)
                {
                    let channelID = e.chatGroupID;
                    this.listen( channelID );
                }

            });

            window.Echo.private('chat-channel')
            .listenForWhisper('typing', (e) => {
                console.log('typing event fired.');
                if( e.id != this.me.id ){
                    let elem = document.getElementById('twic-show-chat-whisper');
                    if( e.typing ){
                        elem.innerHTML = e.name + ' is typing ...';
                    }
                    setTimeout( () => {
                        elem.innerHTML = '';
                      }, 1000)
                }

            })
            this.subscribeToAllChats();
        },
        computed() {

        },
        updated() {
             this.scrollToBottom();
        }
    });
    chatApp.mount(el);
}
// Init tagify


// Global initialization
ChatGlobal.init  = function() {
    ChatGlobal.loadEchoAndPusher();

    let chatElem = document.querySelector('.twic-popup-chat');

    if( chatElem ){
        ChatGlobal.handleChat(chatElem);
    }

};
// Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = ChatGlobal;
}
