"use strict";

// Class definition
var KTAlert = function(element, options) {
    ////////////////////////////
    // ** Private variables  ** //
    ////////////////////////////
    var the = this;

    if (!element) {
        return;
    }

    // Initialize
    var _init = function() {
        
        

    }


    // Construct class
    // Constructor
    var _construct = function() {
        _init();
    }

};

// Create instances
KTAlert.createInstances = function(selector = '[data-kt-link-action-alert="confirm"]') {
    // Get instances
    const handleFormSubmission = function(e) {
        e.preventDefault();
        var event = e;
        const formElem = event.target;
        Swal.fire({
            html: `Are you sure you want to perform this operation?`,
            icon: "warning",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: 'No',
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-danger'
            }
        })
          .then( (action)  => {
            // If the user clicked the "Confirm" button
            
            if ( action.isConfirmed == true ) {
               
                formElem.removeEventListener('submit', handleFormSubmission);
                // console.log(formElem)
                formElem.submit();
            }
          });
    };
    const handleUrlClick =  function(e) {
        e.preventDefault();
        var event = e;
        Swal.fire({
            html: `Are you sure you want to perform this operation?`,
            icon: "warning",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: 'No',
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-danger'
            }
        })
          .then( (action)  => {
            // If the user clicked the "Confirm" button
            // console.log(action);
            if ( action.isConfirmed == true ) {
                window.location.href = event.target.href;
            }
          });
    };
    var elements = document.body.querySelectorAll(selector);
    if ( elements && elements.length > 0 ) {
        for (var i = 0, len = elements.length; i < len; i++) {
            // new KTAlert(elements[i]);
            const isForm = elements[i].getAttribute('data-kt-form-action-alert') != null;
            if( isForm )
            {
                elements[i].addEventListener('submit', handleFormSubmission );
            }
            else
            {
                elements[i].addEventListener('click', handleUrlClick);
            }
            
        }
    }
}

// Global initialization
KTAlert.init = function() {
    KTAlert.createInstances();
};

// Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = KTAlert;
}